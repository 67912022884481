import { lazy, Suspense, useCallback, useEffect } from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import { CustomerlyProvider, useCustomerly } from "react-live-chat-customerly";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { Loader, Toast, ToastProvider } from "design2impact";
import { checkCookie } from "helpers2impact";

import initSentry from "./helpers/sentry";

import config from "./config";

import "./index.css";

const Orient = lazy(() => import("./apps/orient"));
const Talent = lazy(() => import("./apps/talent"));

const tagManagerArgs = {
	gtmId: import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER,
};


const AppSelector = () => {
	const { load } = useCustomerly();

	const loadServices = useCallback(() => {
		TagManager.initialize(tagManagerArgs);

		if (config.isProduction) {
			load({ direction: "right" });
			initSentry();
		}
	}, [load]);

	useEffect(() => {
		loadServices();
	}, [loadServices]);

	const session: any = checkCookie(config.cookieName);
	const SelectedApp = session?.user?.plan_slug === "m1-o26" ? Orient : Talent;

	return (
		<Suspense fallback={<Loader />}>
			<SelectedApp />
		</Suspense>
	);
};

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
	<CustomerlyProvider appId="d915533c">
		<QueryClientProvider client={queryClient}>
			<ToastProvider>
				<AppSelector />
				<Toast />
			</ToastProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</CustomerlyProvider>,
);
